import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import Image, { ImagePropTypes } from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import SubscriptionForm from "@src/components/form-subscription"
import { SITE_DOMAIN } from "@src/utils/constants"
const PromoImageWrapper = styled(ImageWrapper)`
  position: relative;
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 275px;
    height: 366px;
    margin-top: 14px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 385px;
    height: 513px;
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    width: 485px;
    height: 645px;
    margin-top: 8px;
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: 535px;
    height: 713px;
  }
`
const StyledColumns = styled(ResponsiveColumns)`
  padding-top: 20px;
  justify-items: center;
  @media only screen and (${props =>
      props.theme.screen.small.min}) and (${props =>
      props.theme.screen.small.max}) {
    grid-template-columns: minmax(1rem, 1fr) calc(50vw - 2rem) calc(50vw - 2rem) minmax(
        1rem,
        1fr
      );
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & h1 {
      margin-top: 0px;
    }
    & > *:first-child {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      margin-right: 10px;
    }
    & > *:nth-child(2) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      margin-left: 10px;
    }
  }
`
const PageTemplate = ({ data }) => {
  const {
    tenAdventures: {
      page: { uri: pageUri, seo: pageSeo },
    },
  } = data
  GTM.dataLayerPushPageInfo({
    template: "static-summer-challenge",
  })
  return (
    <PrimaryLayout>
      <PageHelmet
        title={normalizeTitle(pageSeo.title)}
        meta={{
          canonical: `${SITE_DOMAIN}${pageUri}`,
          description: pageSeo.metaDesc,
          robots: [pageSeo.metaRobotsNofollow, pageSeo.metaRobotsNoindex],
          image: pageSeo.seoImage,
          openGraph: {
            type: pageSeo.opengraphType,
            modifiedTime: pageSeo.opengraphModifiedTime,
          },
          twitter: {
            data: [],
          },
        }}
      />
      <StyledColumns variant="even">
        <Image
          wrapper={PromoImageWrapper}
          src="/images/summer-challenge-2023.jpg"
          lazyload={false}
        />
        <div>
          <h1 className="fancy">10Adventures Summer Challenge</h1>
          <h2>Get Fit, Reconnect with Friends, Explore.</h2>
          <p>
            Commit to 10 Adventures this summer and make this your best ever
            summer.
          </p>
          <h2>Sign-up for the 10Adventures Challenge</h2>
          <p>
            Sign-up to get a planning PDF, as well as weekly inspiration during
            the challenge.
          </p>
          <SubscriptionForm
            formName="summer-challenge"
            checkboxLabel="Agree to the terms of the 10Adventures Summer Challenge"
            buttonText="Sign me up!"
          />
        </div>
      </StyledColumns>
    </PrimaryLayout>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    tenAdventures: PropTypes.shape({
      page: PropTypes.shape({
        title: PropTypes.string,
        uri: PropTypes.string,
        seo: PropTypes.shape({ ...PageSeoPropTypes }),
      }),
      summerChallengeBgImage: PropTypes.shape({
        image: PropTypes.shape({ ...ImagePropTypes }),
      }),
    }),
  }),
}
export default PageTemplate
export const query = graphql`
  query ($id: ID!) {
    tenAdventures {
      page(id: $id, idType: DATABASE_ID) {
        title
        uri
        seo {
          ...TenAdventuresGQL_PostSEO
        }
      }
    }
  }
`
